import React, { useEffect } from "react";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import profileFilteredListingData from '~data/profileFilteredListingData.json';
import newsFilteredListingData from '~data/newsFilteredListingData.json';
import FilteredListing from '~2-components/FilteredListing/FilteredListing';

const filterListingEg = `import FilteredListing from '~2-components/FilteredListing/FilteredListing'
<FilteredListing data={[data]} tileType={string} tileContainerColumns={string}   />
`;

const SgFilteredListing = () => {

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Filtered Listing</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={filterListingEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <FilteredListing data={profileFilteredListingData} tileType={'profile'} />
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <FilteredListing data={newsFilteredListingData} tileType={'article'} />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgFilteredListing;


